import { faEnvelope, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useT } from "talkr";

export const NotFoundPage: React.FC = () => {
  const { T } = useT();

  return (
    <>
      <style type="text/css">
        {`
       body { background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAxMC8yOS8xMiKqq3kAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzVxteM2AAABHklEQVRIib2Vyw6EIAxFW5idr///Qx9sfG3pLEyJ3tAwi5EmBqRo7vHawiEEERHS6x7MTMxMVv6+z3tPMUYSkfTM/R0fEaG2bbMv+Gc4nZzn+dN4HAcREa3r+hi3bcuu68jLskhVIlW073tWaYlQ9+F9IpqmSfq+fwskhdO/AwmUTJXrOuaRQNeRkOd5lq7rXmS5InmERKoER/QMvUAPlZDHcZRhGN4CSeGY+aHMqgcks5RrHv/eeh455x5KrMq2yHQdibDO6ncG/KZWL7M8xDyS1/MIO0NJqdULLS81X6/X6aR0nqBSJcPeZnlZrzN477NKURn2Nus8sjzmEII0TfMiyxUuxphVWjpJkbx0btUnshRihVv70Bv8ItXq6Asoi/ZiCbU6YgAAAABJRU5ErkJggg==);}
      .error-template {padding: 40px 15px;text-align: center;}
      .error-actions {margin-top:15px;margin-bottom:15px;}
      .error-actions .btn { margin-right:10px; }
      `}
      </style>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="error-template">
              <h1>{T("404.oops")}</h1>
              <h2>{T("404.notfound")}</h2>
              <div className="error-details">
                {T("404.details")}
                Sorry, an error has occured, Requested page not found!
              </div>
              <div className="error-actions">
                <a href="/" className="btn btn-qatar-purple btn-lg">
                  <FontAwesomeIcon
                    className="me-2"
                    icon={faHome}
                  ></FontAwesomeIcon>
                  {T("404.takemehome")}
                </a>
                <a
                  href="mailto:support@laporramundial.com"
                  className="btn btn-qatar-inverted btn-lg"
                >
                  <FontAwesomeIcon
                    className="me-2"
                    icon={faEnvelope}
                  ></FontAwesomeIcon>
                  {T("404.contactus")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
