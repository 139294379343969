import React from "react";
import { useT } from "talkr";
import logo from "../logo512-inverted.png";
import "./HomePage.css";

export const HomePage: React.FC = () => {
  const { T } = useT();

  return (
    <>
      <style type="text/css">
        {`
                  body {
          background-color: var(--primary);
        }
          `}
      </style>
      <div className="container container-purple col-xxl-8 px-4 py-5">
        <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div className="col-10 col-sm-8 col-lg-6">
            <img
              src={logo}
              className="d-block mx-lg-auto img-fluid"
              alt="La Porra del Mundial 2022"
              width="700"
              height="500"
              loading="lazy"
            />
          </div>
          <div className="col-lg-6 text-light">
            <h1 className="display-5 fw-bold lh-1 mb-3">{T("home.title")}</h1>
            <p className="lead">{T("home.description")}</p>
            <div className="d-grid gap-2 d-md-flex justify-content-md-start"></div>
          </div>
        </div>
      </div>
    </>
  );
};
